
































































































































































































































































.order-form {
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  max-width: 700px;
  background: #FFF;
  padding: 15px 35px;
  margin: 0 auto;
  h1 {
    font-size: 40px;
  }
  h1, h3 {
    text-align: center;
    color: rgb(46, 66, 112);
    font-weight: 700;
  }
  .long-term {
    text-align: center;
    font-size: 18px;
    line-height: 1.3em;
    color: #2e4270;
  }

  .form-control {
    border: 2px solid #e6e6e6;
    padding: 12px 18px;
    height: 50px;
    &.vti__input {
      border: none;
    }
  }

  .btn {
    font-weight: bold;
    font-size: 25px;
  }

  .progress {
    overflow: hidden;
    height: 35px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 20px;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
    padding: 10px;
    width: 100%;
    position: relative;
    .percent {
      background-color: rgb(4, 125, 248);
      height: 100%;
      position: absolute;
      top: 0;
      color: #FFF;
      line-height: 35px;
      font-size: 14px;
      font-weight: 600;
      left: 0;
      text-align: center;
    }
  }
}
.order-page {
  .box-upgrade {
    margin-bottom: 30px;
    .btn {
      max-width: 100%;
      padding: 15px 100px;
    }
  }
  .secure-images img {
    width: 300px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .about-us {
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 40px;
    color: rgb(136, 136, 136);
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 600;
    }
    img {
      margin-right: 30px;
      width: 140px;
      border: 1px solid rgba(0,0,0,0.25);
      border-bottom: 2px solid rgba(0,0,0,0.25);
      box-shadow: 0 4px 3px rgb(0 0 0 / 15%), 0 0 2px rgb(0 0 0 / 15%);
      padding: 5px !important;
      border-radius: 200px;
      background: rgba(0,0,0,0.15);
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      img {
        margin-bottom: 30px;
      }
    }
  }
}
